<template>
    <section :class="`${ borde ? 'borde' : '' }`" class="card-promocion p-3 br-8" :style="`${fullWidth ? 'width:100%;' : `width:${fixedWidth}px;`};min-height:139px;`">
        <img src="/img/no-imagen/mercado.svg" class="br-4 obj-cover border" width="80" height="80" />
        <div class="col pr-0">
            <div class="row mx-0 align-items-center">
                <p class="col px-0 f-10 text-general">
                    Nombre del producto
                </p>
                <i :class="`f-15 ${!favorite ? 'icon-heart-empty text-general' : 'icon-heart text-alert'}`" @click="favorite = !favorite" />
            </div>
            <p class="text-grey2 f-10 mt-1">
                Presentacion
            </p>
            <p class="text-general f-600 f-15 mt-1">
                $ 0.000
            </p>
            <p class="text-general-red f-14 pl-4 my-2">
                Añadir
            </p>
            <div class="mn-pill f-11 bg-general text-white">
                {{ texto }}
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props:{
        fullWidth: {
            type: Boolean,
            default: false
        },
        fixedWidth: {
            type: Number,
            default: 350
        },
        borde: {
            type: Number,
            default: 0
        },
        texto: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            favorite: false
        }
    },
}
</script>

<style lang="scss" scoped>
.card-promocion{
    display: flex;
    box-shadow: 0px 3px 6px #0000000D;
}
.borde{
    border: 1px solid var(--color-general);
}
.nombre{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
.border-red{
    border: 1px solid #FF5806;
}
</style>
